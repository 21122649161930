import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef } from "@angular/core";
import { DialogBox } from "./DialogBox";
import { DialogBoxComponent } from "./dialog-box.component";
declare var $: any;

@Injectable({
    providedIn: "root",
})
export class DialogBoxService {
    private DialogBoxComponentRef: any;
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) {}
    private db: DialogBox;

    init(container: any) {
        this.db = new DialogBox();
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DialogBoxComponent);
        this.DialogBoxComponentRef = componentFactory.create(this.injector);

        // Attach the component to the application
        this.appRef.attachView(this.DialogBoxComponentRef.hostView);

        // Append the component to the specific container in the DOM
        container.nativeElement.appendChild((this.DialogBoxComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0]);
        return this;
    }

    setTitle(title: string) {
        this.db.setTitle(title);
        return this;
    }

    setType(type: "success" | "danger" | "warning" | "info" = "success") {
        this.db.setType(type);
    }

    addSubtitle(entry: { key: string; value: string }) {
        this.db.addSubtitle(entry);
        return this;
    }

    setSubtitles(list: { key: string; value: string }[]) {
        this.db.setSubtitles(list);
        return this;
    }

    setDataList(data: { key: string; value: string }[]) {
        this.db.setDataList(data);
        return this;
    }

    addData(entry: { key: string; value: string }) {
        this.db.addData(entry);
        return this;
    }

    toggle() {
        this.updateDialog();
        if (this.DialogBoxComponentRef) {
            this.DialogBoxComponentRef.instance.toggle();
        }
    }

    updateDialog() {
        if (this.DialogBoxComponentRef) {
            this.DialogBoxComponentRef.instance.set(this.db);
        }
    }

    setOnCancel(onCancel: Function) {
        if (this.DialogBoxComponentRef) {
            this.DialogBoxComponentRef.instance.setOnCancel(onCancel);
        }
    }

    setOnConfirm(onConfirm: Function) {
        if (this.DialogBoxComponentRef) {
            this.DialogBoxComponentRef.instance.setOnConfirm(onConfirm);
        }
    }
}
