import { Injectable, OnInit } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Md5 } from "ts-md5/dist/md5";
import { environment } from "../environments/environment";

const agentid = environment.agentid;
const agentpwd = environment.agentpwd;
const apikey = environment.apikey;

const baseURL = environment.targetAPI;
let endpoints = [];
endpoints["airtime"] = "airtime/getoperator/";
endpoints["recharge"] = "money/getmethod/";
endpoints["momo"] = "money/getmethod/";
endpoints["bill"] = "bill/billers/";

let urls = [];
urls["airtime"] = "airtime/v2/";
urls["recharge"] = "account/recharge/v2/";
urls["momo"] = "account/transfer/v2/";
urls["bill"] = "bill/v2/";

let ops = [];
ops["password"] = "account/modifypassword/";
ops["pin"] = "account/pinupdate/";

let historyType = [];
historyType["client"] = "account/history/";
historyType["agent"] = "account/agent/history/v2/";

let balances = [];
balances["client"] = "account/balance/";
balances["agent"] = "account/agent/balance/v2/";

@Injectable({
    providedIn: "root",
})
export class HttpService implements OnInit {
    langage: string;

    constructor(private http: HttpClient) {}

    ngOnInit(): void {
        this.langage = localStorage.getItem("lang");
    }

    login(login: string, password: string, lang: string): any {
        return this.http.post(baseURL + "account/login/", {
            login,
            password,
            lang,
            apikey,
        });
    }

    signUp(data: any) {
        data.agentid = agentid;
        data.agentpwd = agentpwd;

        return this.http.post(baseURL + "account/enrolment/", data);
    }

    getCountries() {
        const lang = localStorage.getItem("lang");
        const params = new HttpParams({ fromObject: { lang } });
        return this.http.get(baseURL + "intl/countries/", { params: params });
    }

    forgotPassword(phone: string) {
        return this.http.post(baseURL + "account/forgotpassword/", {
            phone,
            lang: localStorage.getItem("lang"),
        });
    }

    exec(data, service: string, urlReq: string, great: string, category: string) {
        data.agentpwd = data.agentpwd || agentpwd;
        data.agentid = data.agentid || agentid;
        data.mode = data.mode || "account";
        data.currency = 952;
        data.type = data.type || "";
        data.lang = this.langage;
        data.agentplatform = agentid;
        data.version = 14;
        if (!data.amount) data.amount = "";
        var date = new Date();

        let url = urls[service];

        if (service === "momo") {
            data.hash = Md5.hashStr(data.client + data.amount + apikey);
        }

        if (service === "airtime") {
            data.hash = Md5.hashStr(data.operator + data.reference + data.amount + apikey);
        }

        if (service === "bill") {
            data.hash = Md5.hashStr(data.biller + data.billid + data.amount + apikey);
        }

        if ((category === "cashin" || category === "cashout") && great !== "afrikpay") {
            data.hash = Md5.hashStr(data.provider + data.payref + data.amount + apikey);
            url = "account/recharge/v2/";
        }

        if ((data.type === "cashout" || data.type === "cashin") && data.mode === "cash") {
            data.hash = Md5.hashStr(data.provider + data.payref + data.amount + apikey);
            url = "account/agent/recharge/V2/";
        }

        return this.http.post(baseURL + url, data);
    }

    cashoutExec(data, service, urlReq, great, category) {
        data.agentpwd = data.agentpwd || agentpwd;
        data.agentid = data.agentid || agentid;
        data.mode = data.mode || "account";
        data.currency = 952;
        data.lang = localStorage.getItem("lang");
        data.type = data.type || "";
        data.agentplatform = agentid;
        data.version = 14;
        data.hash = Md5.hashStr(data.provider + data.payref + data.amount + apikey);

        let url = urls[service];

        if ((category === "cashin" || category === "cashout") && great !== "afrikpay") {
            url = "account/recharge/v2/";
        }

        if (((data.type === "cashout" || data.type === "cashin") && data.mode === "cash") || data.type === "recharge") {
            url = "account/agent/recharge/v2/";
        }

        return this.http.post(baseURL + url, data);
    }

    reset(data: any) {
        data.lang = localStorage.getItem("lang");
        data.agentid = agentid;
        data.agentpwd = agentpwd;

        return this.http.post(baseURL + ops[data.op], data);
    }

    selfRecharge(data) {
        data.lang = localStorage.getItem("lang");
        data.apikey = apikey;

        return this.http.post(baseURL + "account/cashout/", data);
    }

    getAgent(data: any) {
        data.lang = localStorage.getItem("lang");
        data.agentpwd = agentpwd;
        data.agentid = agentid;
        return this.http.post(baseURL + "account/getagent/", data);
    }

    getOperators(product: string, mode: string, category: string) {
        const lang = localStorage.getItem("lang");
        var data = category
            ? { mode, category, lang, apikey, source: "client" }
            : { mode, lang, source: "client", apikey };
        const params = new HttpParams({ fromObject: data });
        return this.http.get(baseURL + endpoints[product], { params });
    }

    getData(biller: string, reference: string, client: string, action: string, form = null, amount = 0) {
        var hash = Md5.hashStr(biller + reference + action + apikey);
        return this.http.post(baseURL + "bill/action/", {
            biller,
            reference,
            agentid,
            hash,
            action,
            form,
            amount,
            client,
        });
    }

    getFees(data: any) {
        data.currency = 952;
        data.agentid = data.agentid || agentid;
        const params = new HttpParams({ fromObject: data });
        return this.http.get(baseURL + "getfees/", { params });
    }

    getAllAgent() {
        const lang = localStorage.getItem("lang");
        const params = new HttpParams({ fromObject: { lang } });
        return this.http.get(baseURL + "account/agent/get/", { params: params });
    }

    getBalance(data: any) {
        data.agentid = data.agentid || agentid;
        data.password = agentpwd;
        data.lang = localStorage.getItem("lang");
        data.agentplatform = agentid;

        if (data.mode === "agent") {
            data.hash = Md5.hashStr(data.agentid + apikey);
        } else {
            data.apikey = apikey;
        }

        return this.http.post(baseURL + balances[data.mode], data);
    }

    getHistory(data: any) {
        data.agentid = data.agentid || agentid;
        data.lang = this.langage;

        if (data.mode === "agent") {
            data.hash = Md5.hashStr(data.agentid + apikey);
            data.agentplatform = agentid;
        } else {
            data.apikey = apikey;
            data.password = agentpwd;
        }

        return this.http.post(baseURL + historyType[data.mode], data);
    }

    getRecipient(data: any) {
        data.lang = localStorage.getItem("lang");
        return this.http.post(baseURL + "money/getname/", data);
    }

    changeLang(lang: string, client: string) {
        return this.http.post(baseURL + "account/modifylang/", { lang, client });
    }

    getStatus(id: number) {
        let obj: { [k: string]: any } = {};

        obj.transactionid = id;
        obj.lang = localStorage.getItem("lang");
        obj.agentid = agentid;
        obj.agentpwd = agentpwd;
        obj.apikey = apikey;

        return this.http.post(baseURL + "account/transaction/status/", obj);
    }

    print(url: string, id: string) {
        var newwindow = window.open(url + "?reference=" + id + "&apikey=" + apikey);
        newwindow.focus();
        newwindow.onblur = function () {
            newwindow.close();
        };
        return;
    }

    getPayment(client: string) {
        let obj: { [k: string]: any } = {};
        obj.client = client;
        obj.agentid = agentid;
        obj.agentpwd = agentpwd;
        obj.lang = localStorage.getItem("lang");
        obj.apikey = apikey;
        return this.http.post(baseURL + "account/payment/get/", obj);
    }

    setPaymentStatus(client: string, transactionid: string, status: string, pin: string) {
        let obj: { [k: string]: any } = {};
        obj.client = client;
        obj.transactionid = transactionid;
        obj.status = status;
        obj.lang = localStorage.getItem("lang");
        obj.pin = pin;
        obj.apikey = apikey;
        return this.http.post(baseURL + "account/payment/confirm/", obj);
    }

    confirmWallet(otp: string, transactionid: string, mode: string) {
        let data: { [k: string]: any } = {};
        data.agentid = data.agentid || agentid;
        data.otp = otp;
        data.lang = localStorage.getItem("lang");
        data.transactionid = transactionid;

        return this.http.post(baseURL + "account/" + mode + "/wallet/confirm/", data);
    }

    addWallet(data: any, mode: string) {
        data.agentid = data.agentid || agentid;
        data.password = agentpwd;
        data.lang = localStorage.getItem("lang");

        return this.http.post(baseURL + "account/" + mode + "/wallet/add/", data);
    }

    getWallets(id: string, mode: string) {
        let obj: { [k: string]: any } = {};
        if (mode === "client") {
            obj.client = id;
        } else {
            obj.agentid = id;
        }
        obj.lang = localStorage.getItem("lang");

        return this.http.post(baseURL + "account/" + mode + "/wallet/get/", obj);
    }

    customRequest(url: string, data: any) {
        const params = new HttpParams({ fromObject: data });
        return this.http.get(url, { params: params });
    }
}
