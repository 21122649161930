import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams
} from '@angular/common/http';
import {environment} from '../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class cdnService {
  private url = "https://sandbox.api.afrikpay.com/api/storage/afrikpay/upload/base64/";

  constructor(private http: HttpClient) { }

  upload(filename : string, data : string) {

    return this.http.post(this.url,{filename, data, bucket : ""});
  }
}
