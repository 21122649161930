import { Component, OnInit} from '@angular/core';
// import slide in/out animation
import {slideInOutAnimation } from '../_animations/index';
import { HelperService } from '../helper.service';
import { StoreService } from '../store.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { HttpService } from '../http.service';
import { FormControl } from '@angular/forms';
import { User } from '../classes';
declare var $ : any;


@Component({
  selector: 'app-agent-recharge',
  templateUrl: './agent-recharge.component.html',
  styleUrls: ['./agent-recharge.component.css'],
  // make slide in/out animation available to this component
  animations: [slideInOutAnimation],

  // attach the slide in/out animation to the host (root) element of this component
  host: {
    '[@slideInOutAnimation]': ''
  }
})
export class AgentRechargeComponent implements OnInit {


  modalTitle: string;
  modalText: string;
  modalType: string;
  amount = new FormControl('');
  amountConfirm = new FormControl('');
  amountError = false;
  amountConfirmError = false;


  country_code = '237';
  pinCode: string;

  pPadVisible = false;
  numpad = [];

  user : User;
  lang : string;


  constructor(private helper: HelperService, private store: StoreService,
    private http: HttpService, private translate: TranslateService, private router: Router) { }

  ngOnInit() {
    this.pinCode = "";
    this.user = this.store.getUser();
  }

  isMobile(){
    return this.helper.isMobile();
    this.user = this.store.getUser();
  }

  setModal(type : string, text : string, title : string) {

    this.modalType = type;
    this.modalTitle = title;

    switch (type) {
      case 'danger': {
        this.modalText = text;
        break;
      }

      default:{
        this.translate.get(text).subscribe((res: string) => {
          this.modalText = res;
        });
        break;
      }
    }

  }

  dismissModal(timer: number, dest: string) {
    setTimeout(() => {
      $('#modalPayment').modal('hide');
      (dest) ? this.router.navigateByUrl(dest): null;
    }, timer);
  }

  _cleanString(str: any) {
    str = str.replace(/\s/g, '');

    var output = '';

    for (var i = 0; i < str.length; i++) {
      if (!isNaN(str[i])) output += str[i];
    }

    return output;
  }

  onChangeText($event: any, key: string) {
    var value = $event.target.value;
    var output = '';
    var str = this._cleanString(value);


    if (key === 'amount' && value != '0' && parseInt(value) > 0) {
      str = parseInt(str).toString();
    }

    var rest = str.length % 3;
    var part = Math.trunc(str.length / 3);

    if (str.length > 3) {
      output += str.substring(0, rest);
      str = str.slice(rest);

      for (var i = 0; i < part; i++) {
        output += ' ';
        output += str.substring(0, 3);
        str = str.slice(3);
      }
    } else {
      output = str;
    }

    switch (key) {
      case 'amount': {
        this.amount.setValue(output);
        break;
      }
      case 'amountConfirm': {
        this.amountConfirm.setValue(output);
        break;
      }
    }
  }

  getRefLabel(service: any) {
    if (service.hasOwnProperty('translations')) {
      if (service.translations.hasOwnProperty(this.lang)) {
        return service.translations[this.lang].reflabel || service.reflabel;
      }
    }
    return service.reflabel;
  }



  execute() {

    if(this.pinCode.length < 4) return;

		let data: {
      [k: string]: any
    } = {};
		data.amount = parseInt(this._cleanString(this.amount.value));
		data.phone = this.user.phonecompte;
    data.agentid = this.user.agent.idagent;
    data.pin = this.helper.encrypt(this.pinCode, this.user.phonecompte);

    this.togglePad();

		this.setModal('processing', 'please_wait', 'transaction_processing');
    $('#modalPayment').modal('toggle');

		this.http.selfRecharge(data).subscribe((response : any) => {
      if (response) {

        if (response.code === 200) {

          this.closeOperation();
        }else {

          this.setModal('danger', response.message, '');
          this.dismissModal(2500, null);

        }
      } else {
        this.setModal('warning', 'failed', '');
        this.dismissModal(2500, null);
      }
    });

  }


  closeOperation() {
    this.setModal('success', 'successfull', '');

    this.dismissModal(1500, null);
    this.pinCode = "";

    setTimeout(() => {
      window.history.back();
    }, 2500);

    return;
  }

  addDigit(d: string) {
    if (this.pinCode.length < 4) {
      this.pinCode = this.pinCode + d
    }
  }

  deleteDigit() {
    (this.pinCode.length > 0) ? this.pinCode = this.pinCode.substring(0, this.pinCode.length - 1): null;
  }

  togglePad() {
    var bool = true;

    if (!(parseInt(this._cleanString(this.amount.value)) > 0)) {
      bool = bool && false;
      this.amountError = true;
    }

    if (!(parseInt(this._cleanString(this.amountConfirm.value)) > 0)) {
      bool = bool && false;
      this.amountConfirmError = true;
    }

    if(!bool) return;

    this.pPadVisible = !this.pPadVisible;
    this.numpad = this.helper.getNumpad();
    this.pinCode = "";
  }

  resetFormErrors() {
    this.amountError = false;
    this.amountConfirmError = false;
  }
}
