// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    local: false,
    targetAPI: "https://sandbox.api.afrikpay.com/api/",
    agentid: "3976751939394558",
    agentpwd: "defcabeb29b4096dd635616bbf6a2512",
    apikey: "b8abb33c6dcce7ca255afa4e98e42cc1",
    url: "https://account.afrikpay.org",
    accepturl: "https://account.afrikpay.org/payment/web/success",
    cancelurl: "https://account.afrikpay.org/payment/web/failed",
    storage: "home/afrikpay/demo",
    site: ".afrikpay.org",
    cookieExpirationDelay: 240, //hours
    website: "https://afrikpay.org",
    downloadBill: "https://sandbox.invoice.afrikpay.com/print",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
