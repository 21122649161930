import {Injectable} from '@angular/core';
import {User} from './classes/index';
import {CookieService} from 'ngx-cookie-service';
import {environment} from '../environments/environment';

var exp = new Date();
var time = exp.getTime();
time += 3600 * 1000 * environment.cookieExpirationDelay; // x hour(s)
exp.setTime(time);

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private LOGGED_USER = '_lU';
  private USER_DATA_VALIDITY = '_u_d_v';
  private URL_INFO = 'u_r_params';

  constructor(private cookieService: CookieService) {}

  saveUser(data: Object) {
    localStorage.setItem('currentUser', JSON.stringify(data));
    this.setCookies(data);
  }

  setLoggedStatus(status: Boolean) {
    const broadcaster = new BroadcastChannel('logged-status');
    broadcaster.postMessage(status);
    localStorage.setItem('isLogged', status + '');
  }

  getLoggedStatus() {
    return localStorage.getItem('isLogged') === 'true' ? true : false;
  }

  getUser(): User {
    if (JSON.parse(localStorage.getItem('currentUser')) != null)
      this.setCookies(JSON.parse(localStorage.getItem('currentUser')));
    return new User(JSON.parse(localStorage.getItem('currentUser')));
  }

  deleteUser() {
    localStorage.setItem('currentUser', 'null');
    this.cookieService.delete(this.LOGGED_USER);
    this.cookieService.delete(this.USER_DATA_VALIDITY);
  }

  getLang() {
    return localStorage.getItem('lang');
  }

  saveUrlParams(params: Object) {
    localStorage.setItem(this.URL_INFO, JSON.stringify(params));
  }

  getUrlParams(): Object {
    return JSON.parse(localStorage.getItem(this.URL_INFO));
  }

  deleteSavedUrlParams() {
    localStorage.removeItem(this.URL_INFO);
  }

  setCookies(data: Object) {
    this.cookieService.set(
      this.LOGGED_USER,
      JSON.stringify(data),
      exp,
      '/',
      environment.site,
    );

    this.cookieService.set(
      this.USER_DATA_VALIDITY,
      exp + '',
      exp,
      '/',
      environment.site,
    );
  }
}
