import { Component, OnInit } from '@angular/core';
import { HelperService } from '../helper.service';
import { HttpService } from '../http.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
declare var $ : any;


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  modalTitle: string;
	modalText: string;
  modalType: string;
  lang : string;

  phoneError = false;

  resetForm : FormGroup;
  phoneLength = 9;
  country_code = '237';
  countries = [];

  constructor(private helper : HelperService, private http : HttpService, private router : Router,
    private translate : TranslateService, private formBuilder : FormBuilder) { }

  ngOnInit() {

    this.lang = localStorage.getItem('lang');
    this.getCountries();

    this.resetForm = this.formBuilder.group({
      phone: ['', [Validators.required, Validators.minLength(this.phoneLength), Validators.maxLength(this.phoneLength)]]
    });

  }

  resetFormErrors() {
    this.phoneError = false;
  }

  getName(service: any) {
    if (service.hasOwnProperty('translations')) {
      if (service.translations.hasOwnProperty(this.lang)) {
        return service.translations[this.lang].name || service.name;
      }
    }
    return service.name;
  }

  getCountries(){
    this.http.getCountries().subscribe((response : any) => {
      if(response){
       if(response.code === 200){
        this.countries = response.result;
       }
      }
    })
  }

  reset(){

    this.phoneError = this.resetForm.controls['phone'].invalid; console.log(this.resetForm.invalid);

    if(!this.resetForm.invalid){
        this.setModal('processing', 'please_wait', 'auth');
        $('#modal').modal('toggle');

        var phone = this.country_code + this.resetForm.controls['phone'].value;

        this.http.forgotPassword(phone).subscribe((response : any) => {
            if(response){
              if(response.code === 200){
                this.setModal('success', 'successfull', '');
                this.dismissModal(2500, '/login');
              }else{
                this.setModal('danger', response.message || '', '');
                this.dismissModal(2500, null);
              }
            }else {
              this.setModal('warning', 'failed', '');
              this.dismissModal(2500, null);
            }
          },
          (error : any) => {

            if(error){
              this.setModal('warning', 'failed', '');
              this.dismissModal(2500, null);
            }

          })
        }
  }

  selectCountry(country :any){
    this.country_code = country.code;
    this.phoneLength = country.nationcounttel;
    this.resetForm = this.formBuilder.group({
      phone: ['', [Validators.required, Validators.minLength(this.phoneLength), Validators.maxLength(this.phoneLength)]]
    });
    $('#modalCountry').modal('hide');
  }

  changeCountry(){
    $('#modalCountry').modal('show');
  }

  setModal(type : string, text : string, title : string) {

    this.modalType = type;
    this.modalTitle = title;

    switch (type) {
      case 'danger': {
        this.modalText = text;
        break;
      }

      default:{
        this.translate.get(text).subscribe((res: string) => {
          this.modalText = res;
        });
        break;
      }
    }

  }

  dismissModal(timer : number, dest : string){
    setTimeout(() => {
      $('#modal').modal('toggle');
      (dest) ? this.router.navigateByUrl(dest) : null;
    }, timer);
  }


  isMobile(){
    return this.helper.isMobile();
  }


}
