import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { MatSnackBarModule } from "@angular/material";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./header/header.component";
import { HomeComponent } from "./home/home.component";
import { ServicesComponent } from "./services/services.component";
import { PaymentComponent } from "./payment/payment.component";
import { BottomTabComponent } from "./bottom-tab/bottom-tab.component";
import { AccountComponent } from "./account/account.component";
import { SupportComponent } from "./support/support.component";
import { LoginComponent } from "./login/login.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { PaymentBillComponent } from "./payment-bill/payment-bill.component";
import { HistoryComponent } from "./history/history.component";
import { PosComponent } from "./pos/pos.component";
import { HomeAgentComponent } from "./home-agent/home-agent.component";
import { AgentRechargeComponent } from "./agent-recharge/agent-recharge.component";
import { HistoryAgentComponent } from "./history-agent/history-agent.component";
import { HeaderAgentComponent } from "./header-agent/header-agent.component";
import { LogoutComponent } from "./logout/logout.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { HeaderHomeComponent } from "./header-home/header-home.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { SignupComponent } from "./signup/signup.component";
import { AboutComponent } from "./about/about.component";
import { PayrequestComponent } from "./payrequest/payrequest.component";
import { AddWalletComponent } from "./add-wallet/add-wallet.component";
import { PaymentStatusComponent } from "./payment-status/payment-status.component";
import { AsyncPaymentComponent } from "./async-payment/async-payment.component";
import { CheckoutComponent } from "./checkout/checkout.component";
import { PaymentSubBillsComponent } from "./payment-sub-bills/payment-sub-bills.component";
import { ServiceComponent } from "./service/service.component";
import { LZStringModule, LZStringService } from "ng-lz-string";
import { CookieService } from "ngx-cookie-service";
import { DialogBoxComponent } from "./widgets/dialog-box/dialog-box.component";

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        HomeComponent,
        ServicesComponent,
        PaymentComponent,
        BottomTabComponent,
        AccountComponent,
        SupportComponent,
        LoginComponent,
        ResetPasswordComponent,
        PaymentBillComponent,
        HistoryComponent,
        PosComponent,
        HomeAgentComponent,
        AgentRechargeComponent,
        HistoryAgentComponent,
        HeaderAgentComponent,
        LogoutComponent,
        HeaderHomeComponent,
        ForgotPasswordComponent,
        SignupComponent,
        AboutComponent,
        PayrequestComponent,
        AddWalletComponent,
        PaymentStatusComponent,
        AsyncPaymentComponent,
        CheckoutComponent,
        PaymentSubBillsComponent,
        ServiceComponent,
        DialogBoxComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        LZStringModule,
        MatSnackBarModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: environment.production,
        }),
    ],
    entryComponents: [DialogBoxComponent],
    providers: [LZStringService, CookieService],
    bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
