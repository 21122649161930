import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {StoreService} from './store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'afriktopup';
  you_can_add_app: string;
  want_to_install: string;
  install: string;

  constructor(
    private translate: TranslateService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private store: StoreService,
  ) {
    const arrayLang = ['en', 'fr'];
    const defaultLang = 'fr';
    const userLang = navigator.language;
    let lang = userLang.substring(0, 2).toLowerCase();

    if (arrayLang.lastIndexOf(lang) < 0) {
      lang = defaultLang;
    }

    if (localStorage.getItem('lang')) {
      translate.setDefaultLang(localStorage.getItem('lang'));
    } else {
      translate.setDefaultLang(lang);
      localStorage.setItem('lang', lang);
    }
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.store.saveUrlParams(params);
    });

    this.translate.get('you_can_add_app').subscribe((res: string) => {
      this.you_can_add_app = res;
    });

    this.translate.get('install').subscribe((res: string) => {
      this.want_to_install = res;
    });

    this.translate.get('install').subscribe((res: string) => {
      this.install = res;
    });

    if ((navigator as any).standalone === false) {
      this.snackbar.open(this.you_can_add_app, '', {
        duration: 5000,
      });
    }
    if ((navigator as any).standalone == undefined) {
      if (window.matchMedia('(display-mode:browser').matches) {
        // in browser
        window.addEventListener('beforeinstallprompt', event => {
          event.preventDefault();
          const snackbar = this.snackbar.open(
            this.want_to_install,
            this.install,
            {
              duration: 10000,
            },
          );
          snackbar.onAction().subscribe(() => {
            (event as any).prompt();
            (event as any).userChoice.then(result => {
              if (result.outcome == 'dismissed') {
                console.log('Dismissed');
              } else {
                console.log('Installed');
              }
            });
          });
          return false;
        });
      }
    }
  }
}
