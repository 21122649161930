import { Component, OnInit } from '@angular/core';
import { User } from '../classes/index';
import {StoreService} from '../store.service';
import {TranslateService} from '@ngx-translate/core';
import { HttpService } from '../http.service';
import { Router } from '@angular/router';
import { HelperService } from '../helper.service';
import { slideInOutAnimation,fadeInAnimation } from '../_animations';
declare var $ : any;


@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css'],
  // make slide in/out animation available to this component
  animations: [slideInOutAnimation,fadeInAnimation],

  // attach the slide in/out animation to the host (root) element of this component
  host: {
    '[@slideInOutAnimation]': '', '[@fadeInAnimation]' : ''
  }
})
export class AccountComponent implements OnInit {
  lang : string;
  user : User;
  constructor(private store : StoreService, private helper : HelperService,
    private translate : TranslateService, private http : HttpService, private router : Router ) { }

  ngOnInit() {
    this.lang = localStorage.getItem('lang');
    this.user = this.store.getUser();
  }

  setLang(language: string) {
    if ( !(localStorage.getItem('lang') === language)) {
        this.translate.setDefaultLang(language);
        localStorage.setItem('lang', language);
        this.http.changeLang(language, this.user.phonecompte).subscribe((response) => {
          //Nothing to do
        })
    }
  }

  goToLogout(){
    this.router.navigateByUrl('/logout');
  }

  logout(){
    $('#modalLogout').modal('show');
  }

  isMobile(){
    return this.helper.isMobile();
  }

}
