import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl} from '@angular/forms';
import {HelperService} from '../helper.service';
import {ActivatedRoute, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../classes';
import {Md5} from 'ts-md5/dist/md5';
import { StoreService } from '../store.service';
import { HttpService } from '../http.service';
// import slide in/out animation
import {
  slideInOutAnimation2
} from '../_animations/index';
declare var $ : any;


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  // make slide in/out animation available to this component
  animations: [slideInOutAnimation2],

  // attach the slide in/out animation to the host (root) element of this component
  host: {
    '[@slideInOutAnimation2]': ''
  }
})

export class ResetPasswordComponent implements OnInit {

  operation : string;

  modalTitle : string;
  modalText : string;
  modalType : string;

  isCurrentVisible = false;
  isNewVisible = false;
  isConfirmVisible = false;

  currentError = false;
  newError = false;
  confirmError = false;

  current : FormControl;
  new : FormControl;
  confirm : FormControl;
  user : User;

  placeholders = [];
  placeholder : {
    new: '',
    current: '',
    confirm: '',
    title : '',
    lenght : 1
  };

  passwordForm : FormGroup;


  constructor(private route : ActivatedRoute, private store : StoreService, private helper : HelperService,
    private translate : TranslateService, private router : Router, private http : HttpService) { }

  ngOnInit() {
    this.placeholders['password'] = {
      new: 'new_password',
      current: 'password',
      confirm: 'confirm_password',
      title : 'change_password',
      lenght : 6
    };

    this.placeholders['pin'] = {
      new: 'new_pin',
      current: 'pin_code',
      confirm: 'confirm_pin',
      title : 'change_pin',
      lenght : 4
    };

    this.current = new FormControl('');
    this.new = new FormControl('');
    this.confirm = new FormControl('');

    this.user = this.store.getUser();

    this.route.paramMap.subscribe( paramMap => {
        this.operation = paramMap.get('type');
        this.placeholder = this.placeholders[this.operation];
      }
    );
  }

  getMaxLenght(){
    return (this.operation === 'pin') ? 4 : 50;
  }

  toggle(elt : string){
    switch (elt) {
      case 'current' : {
        this.isCurrentVisible = !this.isCurrentVisible;
        break;
      }
      case 'new' : {
        this.isNewVisible = !this.isNewVisible;
        break;
      }
      case 'confirm' : {
        this.isConfirmVisible = !this.isConfirmVisible;
        break;
      }

      default : break;
    }
  }

  resetFormErrors() {
    this.currentError = false;
    this.newError = false;
    this.confirmError = false;
  }

  setModal(type : string, text : string, title : string) {

    this.modalType = type;
    this.modalTitle = title;

    switch (type) {
      case 'danger': {
        this.modalText = text;
        break;
      }

      default:{
        this.translate.get(text).subscribe((res: string) => {
          this.modalText = res;
        });
        break;
      }
    }

  }

  dismissModal(timer : number, dest : string){
    setTimeout(() => {
      $('#modalChange').modal('toggle');
      (dest) ? this.router.navigateByUrl(dest) : null;
    }, timer);
  }

  change() {
		let bool = true;

		switch (this.operation) {
			case 'password': {console.log(this.current.value);
				if (this.new.value.length < 6) {
					bool = bool && false;
					this.newError = true;
				} else {
					this.newError = false;
				}

				if (this.current.value.length < 6) {
					bool = bool && false;
					this.currentError = true;
				} else {
					this.currentError = false;
					if (!(Md5.hashStr(this.current.value).toString() === this.user.password)) {
						bool = bool && false;
						this.currentError = true;
					}
				}

				break;
			}

			case 'pin': {
				if (this.new.value.length  !== 4) {
					bool = bool && false;
					this.newError = true;
				} else {
					this.newError = false;
				}
				if (this.current.value.length !== 4) {
					bool = bool && false;
					this.currentError = true;
				} else {
					this.currentError = false;
				}
				break;
			}

			default:
		}

		if (!(this.confirm.value === this.new.value)) {
			bool = bool && false;
			this.confirmError = true;
		} else {
			this.confirmError = false;
		}


		if (bool) {

			this.setModal('processing', 'please_wait', 'transaction_processing');
      $('#modalChange').modal('toggle');

			let data: {
      [k: string]: any
      } = {};

			data.phone = this.user.phonecompte;
			data.op = this.operation;


      if (data.op === 'password') {
        data.password = Md5.hashStr(this.current.value).toString();
        data.newpassword = Md5.hashStr(this.new.value).toString();
      }

      if (data.op === 'pin') {
        data.oldpin = this.helper.encrypt(this.current.value, data.phone);
        data.newpin = this.helper.encrypt(this.new.value, data.phone);
      }

			this.http.reset(data).subscribe((response : any) => {
        if (response) {
          if (response.code === 200) {

            this.setModal('success', 'successfull', '');

            setTimeout(() => {
              if (this.operation === 'password') {
                  this.dismissModal(1, 'logout');
              } else {
                window.history.back();
                this.dismissModal(1, null);
              }
            }, 2500);

          } else {

            this.setModal('danger', response.message, '');
            this.dismissModal(2500, null);
          }
        } else {
          this.setModal('warning', 'failed', '');
          this.dismissModal(2500, null);
        }
      },
      (error) => {
        if(error){
          this.setModal('warning', 'failed', '');
          this.dismissModal(2500, null);
        }
      });
		}
  }

  isMobile(){
    return this.helper.isMobile();
  }

}
