import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { StoreService } from './store.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class guard implements CanActivate {

    constructor(private store : StoreService, private router : Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.store.getLoggedStatus()) {
            return true;
          } else {
            this.router.navigate(['/login'], {
              queryParams: {
                return: state.url
              }
            });
            return false;
          }
    }

}