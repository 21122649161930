import { Component, OnInit} from '@angular/core';
// import slide in/out animation  // Last update
import {
  slideInOutAnimation
} from '../_animations/index';
import {
  HttpService
} from '../http.service';
import {
  HelperService
} from '../helper.service';
import {
  FormControl
} from '@angular/forms';
import {
  StoreService
} from '../store.service';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  Router
} from '@angular/router';
import {
  User
} from '../classes';
import { Md5 } from 'ts-md5';

import {environment} from '../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-async-payment',
  templateUrl: './async-payment.component.html',
  styleUrls: ['./async-payment.component.css'],
  // make slide in/out animation available to this component
  animations: [slideInOutAnimation],

  // attach the slide in/out animation to the host (root) element of this component
  host: {
    '[@slideInOutAnimation]': ''
  }
})
export class AsyncPaymentComponent implements OnInit {

  product = null;
  service: any;
  loading = true;
  lang: string;
  withCode = false;
  user: User;

  modalTitle: string;
  modalText: string;
  modalType: string;
  amount = new FormControl('');
  phone = new FormControl('');
  filledPhone = new FormControl('');
  phoneConfirm = new FormControl('');
  code = new FormControl('');
  amountError = false;
  phoneError = false;
  phoneConfirmError = false;
  codeError = false;

  operation = {
    slug: '',
    merchantid: ''
  };

  phoneLength = 9;
  country_code = '237';
  fees = 0;
  fees_agent = 0;
  total = 0;
  pinCode: string;

  isRecipientVisible = false;
  pPadVisible = false;
  numpad = [];

  statusOperation: string;
  totalTime = 0;
  transactionid: number;

  urlReq: string;

  recipient: string;
  withName : false;
  showConfirmBtn = true;
  countries = [];
  operation_title = null;

  manual = false;
  webRedirect = false;

  constructor(private helper: HelperService, private store: StoreService,
    private http: HttpService, private translate: TranslateService, private router: Router) {}

  ngOnInit() {
    this.product = window.history.state.product;
    this.service = window.history.state.service;
    this.operation = window.history.state.operation;
    this.lang = localStorage.getItem('lang');

    if(!this.product || !this.service) this.router.navigateByUrl('/home');
    if(this.product.mode === 'agent' && !this.operation) this.router.navigateByUrl('/agent');
    if(this.operation) this.setDescription(this.operation);

    this.user = this.store.getUser();
    this.setWithcode(this.service);
    this.setWithName(this.service);
    this.pinCode = "";
    this.getCountries();
    this.getWeredirect(this.service);

    if(this.service.wallet) {
      this.filledPhone.setValue(this.service.wallet.account);
      this.phone.setValue(this.service.wallet.account);
      this.phoneConfirm.setValue(this.service.wallet.account);
    }
  }

  getWeredirect(service){
		const pE = service.paymentEndpoints;
		if(Array.isArray(pE)){
			for(var i = 0;  i < pE.length; i++) {
				if(pE[i].slug == 'cashout') {
					this.webRedirect =  pE[i].webRedirect || this.webRedirect;
				}
			}
		}
	}

  getCountries(){
    this.http.getCountries().subscribe((response : any) => {
      if(response){
       if(response.code === 200){
        this.countries = response.result;
       }
      }
    })
  }

  selectCountry(country :any){
    this.country_code = country.code;
    this.phoneLength = country.nationcounttel;
    $('#modalCountry').modal('hide');
  }

  changeCountry(){
    $('#modalCountry').modal('show');
  }


  setWithcode(service: any) {
    if (service.hasOwnProperty('class')) {
      if (service.class.hasOwnProperty('withCode')) this.withCode = service.class.withCode;
    }

    this.withCode = this.withCode && this.product.need_code
  }

  setWithName(service:any){
    if (service.hasOwnProperty('withName')) {
      this.withName = service.withName && this.product.withname;
    }
  }

  getInstructions(service: any) {
    if (service.instructionscode && (this.product.category === 'cashout') && !(this.product.zone === 'agent')) {
      return service.instructionscode;
    }

    return null;
  }

  getRefLabel(service: any) {
    if (service.hasOwnProperty('translations')) {
      if (service.translations.hasOwnProperty(this.lang)) {
        return service.translations[this.lang].reflabel || service.reflabel;
      }
    }
    return service.reflabel;
  }

  getName(service: any) {
    if (service.hasOwnProperty('translations')) {
      if (service.translations.hasOwnProperty(this.lang)) {
        return service.translations[this.lang].name || service.name;
      }
    }
    return service.name;
  }

  setDescription(operation: any) {
    if (operation.hasOwnProperty('translations')) {
      if (operation.translations.hasOwnProperty(this.lang)) {
        this.operation_title = operation.translations[this.lang].description;
      }
    }
  }


  setModal(type : string, text : string, title : string) {

    this.modalType = type;
    this.modalTitle = title;

    switch (type) {
      case 'danger': {
        this.modalText = text;
        break;
      }

      default:{
        this.translate.get(text).subscribe((res: string) => {
          this.modalText = res;
        });
        break;
      }
    }

  }

  dismissModal(timer: number, dest: string) {
    setTimeout(() => {
      $('#modalPayment').modal('hide');
      (dest) ? this.router.navigateByUrl(dest): null;
    }, timer);
  }

  _cleanString(str: any) {
    str = str.replace(/\s/g, '');

    var output = '';

    for (var i = 0; i < str.length; i++) {
      if (!isNaN(str[i])) output += str[i];
    }

    return output;
  }

  onChangeText($event: any, key: string) {
    var value = $event.target.value;
    var output = '';
    var str = this._cleanString(value);


    if (key === 'amount' && value != '0' && parseInt(value) > 0) {
      str = parseInt(str).toString();
    }

    var rest = str.length % 3;
    var part = Math.trunc(str.length / 3);

    if (str.length > 3) {
      output += str.substring(0, rest);
      str = str.slice(rest);

      for (var i = 0; i < part; i++) {
        output += ' ';
        output += str.substring(0, 3);
        str = str.slice(3);
      }
    } else {
      output = str;
    }

    switch (key) {
      case 'amount': {
        this.amount.setValue(output);
        break;
      }
      case 'phone': {
        this.phone.setValue(output);
        break;
      }
      case 'phoneConfirm': {
        this.phoneConfirm.setValue(output);
        break;
      }
      case 'code': {
        this.code.setValue(output);
        break;
      }
    }
  }

  extractFees(debit: any) {
    if (Array.isArray(debit)) {
      for (var i = 0; i < debit.length; i++) {
        if (debit[i].hasOwnProperty('client')) return debit[i].amount;
      }
    } else {
      return debit.amount;
    }

    return null;
  }

  evaluateFees() {
    let data: {
      [k: string]: any
    } = {};
		data.type = (this.product.zone === 'agent') ? this.product.recharge ? 'recharge_'+this.service.slug : (this.operation.slug === 'cashout') ? 'client_api_withdraw' : 'client_api_paybill' : (this.product.category === 'cashout') ? this.service.category + '_' + this.service.slug : 'client_api_paybill';
    data.client1 = (this.product.zone === 'agent') ? this.product.recharge ? '237000000005' : (this.operation) ? this.service.client : null : this.user.phonecompte;
    data.merchantid = (this.operation) ? (this.operation.slug === 'cashin') ? this.operation.merchantid : this.service.merchantid : this.service.merchantid;
    data.merchantid = (this.product.category === 'cashout') ? null : data.merchantid;
    data.currency = 952;
    data.amount = parseInt(this._cleanString(this.amount.value));
    data.agentid = (data.type === 'client_api_withdraw') ? this.service.agentid : null;

    if (this.product.zone === 'agent') {
      if (this.user.hasOwnProperty('agent')) {
        data.agentid = this.user.agent.idagent;
      }
      if (this.operation) {
        if (this.operation.slug === 'cashin') {
          data.client1 = null;
        }

        data.agentid = this.user.agent.idagent;
      }
    }


    var bool = true;


    if (!(data.amount > 0)) {
      bool = bool && false;
      this.amountError = true;
    } else {
      this.amountError = false;
    }




    if (bool) {

      this.setModal('processing', 'please_wait', 'evaluation');
      $('#modalPayment').modal('toggle');

      this.fees = 0;
      this.fees_agent = 0;

      this.http.getFees(data).subscribe((response: any) => {

          if (response) {
            if (response.debit) {
              if (this.extractFees(response.debit) !== null) {
                this.fees = Math.ceil(this.extractFees(response.debit));
              }
            }

            if (response.credit  && this.product.mode === 'agent') {
              if (this.extractFees(response.credit) !== null) {
                this.fees_agent = Math.trunc(this.extractFees(response.credit));
              }
            }
          }
          if (this.product.mode === 'agent') {
            this.total = 0;
          } else {
            this.total = this.fees + this.fees_agent + data.amount;
          }

          this.dismissModal(1000, null);
        },
        (error) => {

          this.dismissModal(1000, null);
        });
    }
  }

  resetFormErrors() {
    this.amountError = false;
    this.phoneError = false;
    this.phoneConfirmError = false;
  }

  retry() {
    $("#modalAlert").modal("hide");
    setTimeout(() => {
      this.execute();
    }, 300)
  }

  toggleModal(action: number) {
    $('#modalDetails').modal('hide');

    let bool = true;

    /*let regex = this.service.accountregex.toString();
    regex = regex.slice(1);
    regex = regex.substring(0, regex.length - 1);
    regex = new RegExp(regex, '');
    var phone = this.phone.value;


    var isMatching = phone.match(regex) ? true : false;
    if (!(isMatching && phone.length > 4)) {
      bool = bool && false;
      this.phoneError = true;
    }

    if (!(parseInt(this._cleanString(this.amount.value)) > 0)) {
      bool = bool && false;
      this.amountError = true;
    }*/

    if(bool){
      this.execute();
    }
  }

  getRandomDigits() {
    return Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10);
  }

  isCard(){
    return (this.service.type === 'card');
  }

  execute() {


    if (this.product.need_pin) {
      if (this.product.mode === 'client') {
        if(this.pinCode.length < 4  && this.product.need_pin) return;
      } else {
        if (this.operation.slug === 'cashin') {
          if(this.pinCode.length < 4  && this.product.need_pin) return;
        }
      }
    }

    let data: {
      [k: string]: any
    } = {};

    this.statusOperation = "";
    this.totalTime = 0;
    let cc = !this.service.wallet ? this.service.international ? this.country_code : '' : '';
    data.operator = this.service.slug;
    data.reference = this.phone.value.trim();
    data.amount = parseInt(this._cleanString(this.amount.value));
    data.account = this.user.phonecompte;
    data.client = this.user.phonecompte;
    data.method = 'money';
    data.provider = this.service.slug;
    data.payref = this.phone.value.trim();
    data.currency = 952
    data.agentid = null;
    data.agentpwd = null;
    data.mode = this.product.mode === 'agent' ? 'cash' : null;
		data.type =  (this.product.recharge) ? 'recharge' : this.product.mode === 'agent' ? this.operation.slug  : this.product.category;
    var date = new Date();
    data.processingnumber = date.getTime().toString() + this.getRandomDigits();
    this.transactionid = data.processingnumber;
		data.identify = 'account.afrikpay.com';
    data.terminaltype = 'web';
    data.accepturl = environment.accepturl;
    data.cancelurl = environment.cancelurl;



    if (this.product.zone === 'agent') {

      data.agentid = this.user.agent.idagent;
      data.agentpwd = this.user.agent.password;

    }

    if (this.service.slug === 'afrikpay') {
      data.merchantid = '2024654329067873';
    }

    var api = this.product.api;
    var urlReq = this.urlReq;
    var slug = this.service.slug;
    var category = this.product.category

    this.http.cashoutExec(
      data,
      api,
      urlReq,
      slug,
      category
    ).subscribe((response: any) => {
      if (response) {
        if (response.code === 200 && response.result) {
          $('#modalPayment').modal('hide');

          if(this.webRedirect){
            window.open(response.result.paymentlink, '_blank');
            return;
          }

          if(this.isMobile()){
            this.router.navigateByUrl('home/services/payment/async/checkout' , {state : {url : response.result.paymentlink}});
            return;
          }

          this.router.navigateByUrl('home/payment/async/checkout' , {state : {url : response.result.paymentlink}});


        } else {

          this.modalText = response.message;
          $("#modalPayment").modal("hide");
          $("#modalAlert").modal("show");

        }
      } else {
        this.setModal('warning', 'transaction_failed', '');
        this.dismissModal(2500, null);
      }
    });

    this.setModal('processing', 'please_wait', 'transaction_processing');
    $('#modalPayment').modal('toggle');

  }










  isMobile(){
    return this.helper.isMobile();
  }

}
