import { Component, OnInit } from "@angular/core";
import { HttpService } from "../http.service";
import { StoreService } from "../store.service";
import { HelperService } from "../helper.service";
import * as moment from "moment";
import "moment/min/locales";
import { User } from "../classes/index";
// import fade in animation
import { fadeInAnimation } from "../_animations/index";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { FormControl } from "@angular/forms";
declare var $: any;

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.css"],
    // make fade in animation available to this component
    animations: [fadeInAnimation],

    // attach the fade in animation to the host (root) element of this component
    host: { "[@fadeInAnimation]": "" },
})
export class HomeComponent implements OnInit {
    products = {
        airtime: {
            title: "airtime_internet",
            second_title: "buy_airtime",
            item_header: "recharge",
            api: "airtime",
            need_pin: true,
            need_code: false,
            payment_view: "payment",
            mode: "client",
            zone: "client",
            category: "airtime",
            withname: false,
        },
        recharge: {
            title: "topup_with",
            second_title: "topup_account",
            item_header: "topup_account_with",
            api: "recharge",
            need_pin: false,
            need_code: true,
            payment_view: "payment",
            mode: "client",
            zone: "client",
            category: "cashout",
            withname: false,
        },
        bill: {
            title: "pay_bill",
            second_title: "bill_payment_subs",
            item_header: "",
            api: "bill",
            need_pin: true,
            need_code: false,
            payment_view: "paybill",
            mode: "client",
            zone: "client",
            category: "bill",
            withname: false,
        },
        momo: {
            title: "with_my_account",
            second_title: "with_my_account",
            item_header: "topup_my_account",
            api: "momo",
            need_pin: true,
            need_code: false,
            payment_view: "payment",
            mode: "client",
            zone: "client",
            category: "cashin",
            withname: true,
        },
        collect: {
            title: "collect_donation",
            second_title: "collect_donation",
            item_header: "donate_for",
            api: "bill",
            need_pin: true,
            zone: "client",
            payment_view: "paybill",
            mode: "client",
            category: "collect",
            withname: false,
        },
        school: {
            title: "tuition_fees",
            second_title: "tuition_fees",
            item_header: "pay_fees_for",
            api: "bill",
            need_pin: true,
            payment_view: "paybill",
            mode: "client",
            zone: "client",
            category: "school",
            withname: false,
        },
        service: {
            title: "services",
            second_title: "services",
            item_header: "service_of",
            api: "bill",
            need_pin: true,
            payment_view: "paybill",
            mode: "client",
            zone: "client",
            category: "service",
            withname: false,
        },
    };

    product: any;

    loading: boolean;
    request: string;
    services = null;
    currentRoute: string;
    lang: string;
    user: User;
    pinCode: FormControl;
    padVisible = false;
    numpad = [];
    wallets = [];
    balance = null;
    modalTitle: string;
    modalText: string;
    modalType: string;

    constructor(
        private router: Router,
        private http: HttpService,
        private helper: HelperService,
        private store: StoreService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.user = this.store.getUser();
        this.pinCode = new FormControl("");
    }

    goTo(route: string, data: JSON): void {
        this.router.navigateByUrl(route, { state: { product: data } });
    }

    getName(service: any) {
        if (service.hasOwnProperty("translations")) {
            if (service.translations.hasOwnProperty(localStorage.getItem("lang"))) {
                return service.translations[localStorage.getItem("lang")].name || service.name;
            }
        }
        return service.name;
    }

    goToService(data) {
        this.product = data;
        this.loading = true;
        this.request = "operators";
        this.services = null;
        const product = data;

        if (this.helper.isMobile()) this.goTo("home/services", data);

        if (!this.helper.isMobile()) {
            if (this.product.category === "cashout") {
                var id = this.product.zone === "client" ? this.user.phonecompte : this.user.agent.idagent;

                this.http.getWallets(id, this.product.zone).subscribe((response: any) => {
                    if (response) {
                        if (response.code === 200) {
                            this.wallets = response.result;

                            this.http
                                .getOperators(product.api, product.mode, product.category)
                                .subscribe((response: any) => {
                                    this.services = response.result;
                                    this.loading = false;
                                    this.request = "";

                                    for (let i = 0; i < this.services.length; i++) {
                                        for (const method of this.wallets) {
                                            if (this.services[i].slug === method.provider) {
                                                this.services[i].wallet = method;
                                                break;
                                            }
                                        }
                                    }
                                });
                        }
                    }
                });
            } else {
                this.http.getOperators(product.api, product.mode, product.category).subscribe((response: any) => {
                    this.services = response.result;
                    this.loading = false;
                    this.request = "";
                });
            }
        }
    }

    getAgent() {
        let data = {
            phone: this.user.phonecompte,
            password: this.user.password,
        };

        this.setModal("processing", "please_wait", "checking_account");
        $("#modal").modal("toggle");

        this.http.getAgent(data).subscribe((response: any) => {
            if (response) {
                if (response.code === 200) {
                    this.user.setAgent(response.result);
                    this.store.saveUser(this.user);
                    this.dismissModal(1, "agent");
                } else {
                    this.setModal("danger", response.message, "");
                    this.dismissModal(2500, null);
                }
            } else {
                this.setModal("warning", "failed", "");
                this.dismissModal(2500, null);
            }
        });
    }

    // isPaypalOrCard(s : string){
    //   return s == 'paypal' || s == 'card';
    // }

    isPaypalOrCard(s: any) {
        return s.type === "paypal" || s.type === "card";
    }

    goToPayment(service: any): void {
        if (service.comingsoon) {
            $("#modalUnavailable").modal("show");
            setTimeout(() => {
                $("#modalUnavailable").modal("hide");
            }, 2500);
            return;
        }

        if (!service.wallet && this.product.category === "cashout") {
            this.router.navigateByUrl("home/wallet/add", { state: { product: this.product, service: service } });
            return;
        }

        if (this.isPaypalOrCard(service) && this.product.category === "cashout") {
            this.router.navigateByUrl("home/payment/async", { state: { product: this.product, service: service } });
        } else {
            this.router.navigateByUrl("home/" + this.product.payment_view, {
                state: { product: this.product, service: service },
            });
        }
    }

    getDate() {
        return moment(this.user.datelog).locale(localStorage.getItem("lang")).format("LLL");
    }

    addDigit(d: string) {
        if (this.pinCode.value.length < 4) {
            this.pinCode.setValue(this.pinCode.value + d);
        }
    }

    deleteDigit() {
        this.pinCode.value.length > 0
            ? this.pinCode.setValue(this.pinCode.value.substring(0, this.pinCode.value.length - 1))
            : null;
    }

    togglePad() {
        this.padVisible = !this.padVisible;
        this.numpad = this.helper.getNumpad();
        this.pinCode.setValue("");
    }

    getBalance() {
        // if(this.pinCode.value.length < 4) return;

        let data = {
            phone: this.user.phonecompte,
            mode: "client",
            pin: this.helper.encrypt(this.pinCode.value, this.user.phonecompte),
        };

        this.setModal("processing", "please_wait", "checking_balance");
        $("#modal").modal("toggle");
        // this.togglePad();

        this.http.getBalance(data).subscribe(
            (response: any) => {
                if (response) {
                    if (response.code === 200) {
                        this.balance = response.result.reservebalance;
                        this.dismissModal(1, null);
                    } else {
                        this.setModal("danger", response.message || "", "");
                        this.dismissModal(2500, null);
                    }
                } else {
                    this.setModal("warning", "failed", "");
                    this.dismissModal(2500, null);
                }
            },
            (error: any) => {
                if (error) {
                    this.setModal("warning", "failed", "");
                    this.dismissModal(2500, null);
                }
            }
        );
    }

    checkClass(elt) {
        if (!this.product) return false;
        return !this.helper.isMobile() && this.product.title === elt.title;
    }

    setModal(type: string, text: string, title: string) {
        this.modalType = type;

        switch (type) {
            case "processing": {
                this.modalTitle = title;
                this.translate.get("please_wait").subscribe((res: string) => {
                    this.modalText = res;
                });
                break;
            }

            case "warning": {
                this.modalTitle = "";
                this.translate.get("failed").subscribe((res: string) => {
                    this.modalText = res;
                });
                break;
            }

            case "danger": {
                this.modalTitle = "";
                this.modalText = text;
                break;
            }

            default:
                break;
        }
    }

    dismissModal(timer: number, dest: string) {
        setTimeout(() => {
            $("#modal").modal("toggle");
            dest ? this.router.navigateByUrl(dest) : null;
        }, timer);
    }

    isMobile() {
        return this.helper.isMobile();
    }
}
