import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from '../app/home/home.component';
import {ServicesComponent} from './services/services.component';
import {PaymentComponent} from './payment/payment.component';
import {AccountComponent} from './account/account.component';
import {SupportComponent} from './support/support.component';
import {LoginComponent} from './login/login.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {PaymentBillComponent} from './payment-bill/payment-bill.component';
import {HistoryComponent} from './history/history.component';
import {PosComponent} from './pos/pos.component';
import {LogoutComponent} from './logout/logout.component';
import {guard} from './guard';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {SignupComponent} from './signup/signup.component';
import {AboutComponent} from './about/about.component';
import {PayrequestComponent} from './payrequest/payrequest.component';
import {AddWalletComponent} from './add-wallet/add-wallet.component';
import {PaymentStatusComponent} from './payment-status/payment-status.component';
import {AsyncPaymentComponent} from './async-payment/async-payment.component';
import {CheckoutComponent} from './checkout/checkout.component';
import {PaymentSubBillsComponent} from './payment-sub-bills/payment-sub-bills.component';
import {ServiceComponent} from './service/service.component';

const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'logout', component: LogoutComponent, canActivate: [guard]},
  {path: 'payment/:status', component: PaymentStatusComponent},
  {path: 'payment/web/:status', component: PaymentStatusComponent},
  {
    path: 'home',
    component: HomeComponent,
    children: [
      {
        path: 'services',
        component: ServicesComponent,
        children: [
          {path: 'payment', component: PaymentComponent},
          {path: 'payment/async', component: AsyncPaymentComponent},
          {
            path: 'paybill',
            component: PaymentBillComponent,
            children: [
              {path: 'sub-bills', component: PaymentSubBillsComponent},
              {path: 'service', component: ServiceComponent},
            ],
          },
          {path: 'wallet/add', component: AddWalletComponent},
          {path: 'paybill', component: PaymentBillComponent},
          {path: 'payment/async/checkout', component: CheckoutComponent},
        ],
      },
      {path: 'payment', component: PaymentComponent},
      {path: 'payment/async', component: AsyncPaymentComponent},
      {path: 'payment/async/checkout', component: CheckoutComponent},
      {
        path: 'paybill',
        component: PaymentBillComponent,
        children: [
          {path: 'sub-bills', component: PaymentSubBillsComponent},
          {path: 'service', component: ServiceComponent},
        ],
      },
      {path: 'history', component: HistoryComponent},
      {path: 'pos', component: PosComponent},
      {path: 'wallet/add', component: AddWalletComponent},
      {
        path: 'account',
        component: AccountComponent,
        children: [{path: 'reset/:type', component: ResetPasswordComponent}],
      },
      {path: 'support', component: SupportComponent},
      {path: 'about', component: AboutComponent},
      {path: 'payrequest', component: PayrequestComponent},
    ],
    canActivate: [guard],
  },
  {
    path: 'account',
    component: AccountComponent,
    children: [{path: 'reset/:type', component: ResetPasswordComponent}],
    canActivate: [guard],
  },
  {path: 'support', component: SupportComponent, canActivate: [guard]},
  {path: 'about', component: AboutComponent, canActivate: [guard]},
  {path: 'payrequest', component: PayrequestComponent, canActivate: [guard]},
  {path: 'login', component: LoginComponent},
  {path: 'forgot/password', component: ForgotPasswordComponent},
  {path: 'signup', component: SignupComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}

// for easy import into app module
export const routedComponents = [
  HomeComponent,
  ServicesComponent,
  PaymentComponent,
];
