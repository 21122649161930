import { Component, OnInit, HostListener} from '@angular/core';
import {environment} from '../../environments/environment';
// import slide in/out animation  // Last update
import {
  slideInOutAnimation
} from '../_animations/index';
import { HelperService } from '../helper.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
  // make slide in/out animation available to this component
  animations: [slideInOutAnimation],

  // attach the slide in/out animation to the host (root) element of this component
  host: {
    '[@slideInOutAnimation]': ''
  }
})
export class CheckoutComponent implements OnInit {
  // "https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=EC-8W06026497493015L";

  url: string;
  urlSafe: SafeResourceUrl;
  loader = true;

  @HostListener('window:message', ['$event'])
  onMessage(e) {
    if (e.origin != environment.url) { // set your origin
      return false;
    }
    if (e.data.for == "user") {
      this.router.navigateByUrl('/home');
    }
  }

  constructor(private helper: HelperService, public sanitizer: DomSanitizer, private router : Router) { }

  ngOnInit() {
    this.url = window.history.state.url;
    if(!this.url) this.router.navigateByUrl('/home');
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  isMobile(){
    return this.helper.isMobile();
  }

  hideLoader() {
    setTimeout(() => {
      this.loader = false;
    }, 3000)
  }

}
