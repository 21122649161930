import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpService } from '../http.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {Md5} from 'ts-md5/dist/md5';
declare var $ : any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  emailError = false;
  nicError = false;
  firstnameError = false;
  lastnameError = false;
  passwordError = false;
  confirmError = false;
  phoneError = false;

  country_code = '237';
  phoneLength = 9;

  passwordVisible = false;
  confirmVisible = false;

  civility : FormControl;
  email : FormControl;
  nic : FormControl;
  firstname : FormControl;
  lastname : FormControl;
  password : FormControl;
  confirm : FormControl;
  phone : FormControl;

  lang : string;
  countries = [];
  countryName = 'CM';

  modalType : string;
  modalTitle: string;
  modalText : string;

  modalMode : string;
  parentAccount : FormControl;
  parentAccountError = false;
  parentPhoneLength = 9;
  parent_country_code = '237';


  constructor(private http : HttpService, private router : Router,
    private translate : TranslateService) { }

  ngOnInit() {
    this.civility = new FormControl('mr');
    this.email = new FormControl('');
    this.nic = new FormControl('');
    this.firstname = new FormControl('');
    this.lastname = new FormControl('');
    this.password = new FormControl('');
    this.confirm = new FormControl('');
    this.phone = new FormControl('');
    this.parentAccount = new FormControl('');
    this.lang = localStorage.getItem('lang');
    this.getCountries()

  }


  getName(service: any) {
    if (service.hasOwnProperty('translations')) {
      if (service.translations.hasOwnProperty(this.lang)) {
        return service.translations[this.lang].name || service.name;
      }
    }
    return service.name;
  }

  getCountries(){
    this.http.getCountries().subscribe((response : any) => {
      if(response){
       if(response.code === 200){
        this.countries = response.result;
       }
      }
    })
  }

  selectCountry(country :any){
    if(this.modalMode == 'parent'){
      this.parent_country_code = country.code;
      this.parentPhoneLength = country.nationcounttel;
    } else {
      this.country_code = country.code;
      this.phoneLength = country.nationcounttel;
      this.countryName = country.isoL;
    }
    $('#modalCountry').modal('hide');
  }

  changeCountry(mode :string){
    this.modalMode = mode;
    $('#modalCountry').modal('show');
  }

  resetFormErrors() {
    this.emailError = false;
    this.nicError = false;
    this.firstnameError = false;
    this.lastnameError = false;
    this.passwordError = false;
    this.confirmError = false;
    this.phoneError = false;
  }


  toggle(elt : string){
    switch (elt) {
      case 'password' : {
        this.passwordVisible = !this.passwordVisible;
        break;
      }
      case 'confirm' : {
        this.confirmVisible = !this.confirmVisible;
        break;
      }

      default : break;
    }
  }

  setModal(type : string, text : string, title : string) {

    this.modalType = type;
    this.modalTitle = title;

    switch (type) {
      case 'danger': {
        this.modalText = text;
        break;
      }

      default:{
        this.translate.get(text).subscribe((res: string) => {
          this.modalText = res;
        });
        break;
      }
    }

  }

  dismissModal(timer: number, dest: string) {
    setTimeout(() => {
      $('#modal').modal('hide');
      (dest) ? this.router.navigateByUrl(dest): null;
    }, timer);
  }

  quit(){
    setTimeout(() => {
      this.router.navigateByUrl('/login');
    }, 2500);
  }

  signup() {
		let bool = true;

		const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
    const isMatching = this.email.value.match(emailRegexp) ? true : false;

    if (this.lastname.value.length < 2) {
			bool = bool && false;
			this.lastnameError = true;
		}else {
        this.lastnameError = false;
    }
		if (this.firstname.value.length < 2) {
			bool = bool && false;
			this.firstnameError = true;
		}else {
      this.firstnameError = false;
    }
		if (this.nic.value.length < 5) {
			bool = bool && false;
			this.nicError = true;
		}else {
      this.nicError = false;
    }

		if (!isMatching) {
			bool = bool && false;
			this. emailError = true;
		} else {
			this. emailError = false;
		}

		if (this.password.value.length < 6) {
			bool = bool && false;
			this.passwordError = true;
		} else {
			this.passwordError = false;
		}

		if (!(this.confirm.value === this.password.value)) {
			bool = bool && false;
			this.confirmError = true;
		} else {
			this.confirmError = false;
		}

		if (!(this.phone.value.trim().length === this.phoneLength)) {
			bool = bool && false;
			this.phoneError = true;
		} else {
			this.phoneError = false;
    }


    let parent = '';

    if(this.parentAccount.value.trim().length > 0){
      if (!(this.parentAccount.value.trim().length === this.parentPhoneLength)) {
        bool = bool && false;
        this.parentAccountError = true;
      } else {
        this.parentAccountError = false;
        parent = this.parent_country_code + this.parentAccount.value.trim();
      }
    }

		if (bool) {

      let data = {
        email : this.email.value,
        password : Md5.hashStr(this.password.value).toString(),
        phone : this.country_code + this.phone.value.trim(),
        firstname : this.firstname.value,
        lastname : this.lastname.value,
        login : this.phone.value.trim(),
        country : this.countryName,
        lang : localStorage.getItem('lang'),
        idnumber : this.nic.value,
        civility : this.civility.value
      }

      if(parent.length > 0) data = Object.assign(data, {parent : parent});

      this.setModal('processing', 'please_wait', 'signing_up');
      $('#modal').modal('toggle');

			this.http.signUp(data).subscribe((response : any) => {
				if (response) {
					if (response.code === 200) {

            this.dismissModal(1, null);
            $("#modalManual").modal("show");


					} else {

						this.setModal('danger', response.message, '');
            this.dismissModal(2500, null);
					}
				} else {
					this.setModal('warning', 'failed', '');
          this.dismissModal(2500, null);
				}
      },
      (error) => {
        if(error){
          this.setModal('warning', 'failed', '');
          this.dismissModal(2500, null);
        }
      });
		}
	}

}
