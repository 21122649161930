import {Component, OnInit} from '@angular/core';
import {HelperService} from '../helper.service';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpService} from '../http.service';
import {Md5} from 'ts-md5/dist/md5';
import {Router} from '@angular/router';
import {StoreService} from '../store.service';
import {environment} from '../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  modalTitle: string;
  modalText: string;
  modalType: string;
  lang: string;

  phoneError = false;
  passwordError = false;
  isPasswordVisible = false;

  loginForm: FormGroup;
  phoneLength = 9;
  country_code = '237';
  countries = [];

  constructor(
    private helper: HelperService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private http: HttpService,
    private router: Router,
    private store: StoreService,
  ) {}

  ngOnInit() {
    if (this.store.getLoggedStatus()) {
      this.checkExternalAuthProcess();
      this.router.navigateByUrl('home');
    }

    this.loginForm = this.formBuilder.group({
      phone: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });

    this.lang = localStorage.getItem('lang');
    this.getCountries();
  }

  getCountries() {
    this.http.getCountries().subscribe((response: any) => {
      if (response) {
        if (response.code === 200) {
          this.countries = response.result;
        }
      }
    });
  }

  selectCountry(country: any) {
    this.country_code = country.code;
    this.phoneLength = country.nationcounttel;
    $('#modalCountry').modal('hide');
  }

  changeCountry() {
    $('#modalCountry').modal('show');
  }

  login() {
    this.phoneError = !(
      this.loginForm.controls['phone'].value.length === this.phoneLength
    );
    this.passwordError = this.loginForm.controls['password'].invalid;

    if (!this.loginForm.invalid) {
      if (navigator.onLine) {
        this.setModal('processing', '', 'auth');
        $('#modal').modal('toggle');

        var phone = this.loginForm.controls['phone'].value;
        var password = this.loginForm.controls['password'].value;

        this.http
          .login(
            this.country_code + phone,
            Md5.hashStr(password).toString(),
            this.lang,
          )
          .subscribe(
            (response: any) => {
              if (response) {
                if (response.code === 200) {
                  this.store.saveUser(response.result);
                  this.store.setLoggedStatus(true);
                  this.dismissModal(2000, '/home');
                } else {
                  this.setModal('danger', response.message || '', '');
                  this.dismissModal(2500, null);
                }
              } else {
                this.setModal('warning', 'failed', '');
                this.dismissModal(2500, null);
              }
            },
            (error: any) => {
              if (error) {
                this.setModal('warning', 'failed', '');
                this.dismissModal(2500, null);
              }
            },
          );
      }
    }
  }

  getName(service: any) {
    if (service.hasOwnProperty('translations')) {
      if (service.translations.hasOwnProperty(this.lang)) {
        return service.translations[this.lang].name || service.name;
      }
    }
    return service.name;
  }

  resetFormErrors() {
    this.phoneError = false;
    this.passwordError = false;
  }

  setModal(type: string, text: string, title: string) {
    this.modalType = type;

    switch (type) {
      case 'processing': {
        this.modalTitle = title;
        this.translate.get('please_wait').subscribe((res: string) => {
          this.modalText = res;
        });
        break;
      }

      case 'warning': {
        this.modalTitle = '';
        this.translate.get('failed').subscribe((res: string) => {
          this.modalText = res;
        });
        break;
      }

      case 'danger': {
        this.modalTitle = '';
        this.modalText = text;
        break;
      }

      default:
        break;
    }
  }

  dismissModal(timer: number, dest: string) {
    setTimeout(() => {
      $('#modal').modal('toggle');
      this.checkExternalAuthProcess();
      dest ? this.router.navigateByUrl(dest) : null;
    }, timer);
  }

  checkExternalAuthProcess() {
    let params = this.store.getUrlParams();

    if (params) {
      if (params.hasOwnProperty('action')) {
        if (params['action'] === 'auth') {
          window.location.href = environment.website + '#' + params['service'];
        }
      }
    }
  }

  togglePassword() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  isMobile() {
    return this.helper.isMobile();
  }
}
