export class DialogBox {
    private title: string = "successful_operation"; //Should be a dictionary key
    private type: "success" | "danger" | "warning" | "info" = "success";
    private subtitles: { key: string; value: string }[] = [];
    private dataList: { key: string; value: string }[] = [];

    constructor() {}

    clone(db: DialogBox) {
        if (!!db) {
            this.title = db.title;
            this.type = db.type;
            this.subtitles = db.subtitles;
            this.dataList = db.dataList;
        }
    }

    setTitle(title: string) {
        this.title = title;
    }

    setType(type: "success" | "danger" | "warning" | "info" = "success") {
        this.type = type;
    }

    addSubtitle(entry: { key: string; value: string }) {
        this.subtitles.push(entry);
    }

    setSubtitles(list: { key: string; value: string }[]) {
        this.subtitles = list;
    }

    setDataList(data: { key: string; value: string }[]) {
        this.dataList = data;
    }

    addData(entry: { key: string; value: string }) {
        this.dataList.push(entry);
    }

    getSubtitles() {
        return this.subtitles;
    }

    getDataList() {
        return this.dataList;
    }

    getTitle() {
        return this.title;
    }
}
