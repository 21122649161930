import { Component, OnInit } from "@angular/core";
// import slide in/out animation
import { slideInOutAnimation } from "../_animations/index";
import { HttpService } from "../http.service";
import { HelperService } from "../helper.service";
import * as moment from "moment";
import "moment/min/locales";
import { User } from "../classes/index";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { StoreService } from "../store.service";
declare var $: any;

@Component({
    selector: "app-history",
    templateUrl: "./history.component.html",
    styleUrls: ["./history.component.css"],
    // make slide in/out animation available to this component
    animations: [slideInOutAnimation],

    // attach the slide in/out animation to the host (root) element of this component
    host: { "[@slideInOutAnimation]": "" },
})
export class HistoryComponent implements OnInit {
    request: string;
    services = null;
    currentRoute: string;
    lang: string;
    user: User;
    pinCode: string;
    padVisible = false;
    numpad = [];
    balance = null;
    modalTitle: string;
    modalText: string;
    modalType: string;
    history = [];

    moreBtn = true;
    loading = false;

    page = 1;

    constructor(
        private http: HttpService,
        private helper: HelperService,
        private store: StoreService,
        private router: Router,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        // this.togglePad(1);
        this.user = this.store.getUser();
        this.lang = this.store.getLang();
        this.pinCode = "";
        this.getBalance();
    }

    getHistory(data: any) {
        this.setModal("processing", "please_wait", "loading_transactions");
        this.http.getHistory(data).subscribe(
            (response: any) => {
                if (response) {
                    if (response.code === 200) {
                        this.history = response.result;
                        this.page++;
                        this.dismissModal(1);
                    } else {
                        this.setModal("danger", response.message || "", "");
                        this.dismissModal(2500);
                    }
                } else {
                    this.setModal("warning", "failed", "");
                    this.dismissModal(2500);
                }
            },
            (error: any) => {
                if (error) {
                    this.setModal("warning", "failed", "");
                    this.dismissModal(2500);
                }
            }
        );
    }

    getBalance() {
        // if (this.pinCode.length < 4) return;

        let data = {
            phone: this.user.phonecompte,
            mode: "client",
            pin: this.helper.encrypt(this.pinCode, this.user.phonecompte),
            page: this.page,
            count: 10,
        };

        this.setModal("processing", "please_wait", "checking_balance");
        $("#modal").modal("toggle");
        // this.togglePad(1);

        this.http.getBalance(data).subscribe(
            (response: any) => {
                if (response) {
                    if (response.code === 200) {
                        this.balance = response.result.reservebalance;
                        this.getHistory(data);
                    } else {
                        this.setModal("danger", response.message || "", "");
                        this.dismissModal(2500);
                    }
                } else {
                    this.setModal("warning", "failed", "");
                    this.dismissModal(2500);
                }
            },
            (error: any) => {
                if (error) {
                    this.setModal("warning", "failed", "");
                    this.dismissModal(2500);
                }
            }
        );
    }

    setModal(type: string, text: string, title: string) {
        this.modalType = type;

        switch (type) {
            case "processing": {
                this.modalTitle = title;
                this.translate.get("please_wait").subscribe((res: string) => {
                    this.modalText = res;
                });
                break;
            }

            case "warning": {
                this.modalTitle = "";
                this.translate.get("failed").subscribe((res: string) => {
                    this.modalText = res;
                });
                break;
            }

            case "danger": {
                this.modalTitle = "";
                this.modalText = text;
                break;
            }

            default:
                break;
        }
    }

    togglePad(state: number) {
        this.padVisible = !this.padVisible;
        this.numpad = this.helper.getNumpad();
        if (!state) window.history.back();
    }

    dismissModal(timer: number) {
        setTimeout(() => {
            $("#modal").modal("toggle");
            if (!(timer === 1)) window.history.back();
        }, timer);
    }

    addDigit(d: string) {
        if (this.pinCode.length < 4) {
            this.pinCode = this.pinCode + d;
        }
    }

    deleteDigit() {
        this.pinCode.length > 0 ? (this.pinCode = this.pinCode.substring(0, this.pinCode.length - 1)) : null;
    }

    getDate(date: string) {
        return moment(date).locale(this.lang).format("LLL");
    }

    getType(type: string) {
        return this.helper.getType(type, this.lang);
    }

    abs(amount: number) {
        return Math.abs(amount);
    }

    updateHistory() {
        let data = {
            phone: this.user.phonecompte,
            mode: "client",
            pin: this.helper.encrypt(this.pinCode, this.user.phonecompte),
            page: this.page,
        };

        this.loading = true;
        this.moreBtn = false;

        this.http.getHistory(data).subscribe(
            (response: any) => {
                if (response) {
                    if (response.code === 200) {
                        this.history = this.history.concat(response.result);
                        this.page++;
                        this.loading = false;
                        this.moreBtn = true;
                    } else {
                        this.loading = false;
                    }
                } else {
                    this.loading = false;
                    this.moreBtn = true;
                }
            },
            (error: any) => {
                if (error) {
                    this.loading = false;
                    this.moreBtn = true;
                }
            }
        );
    }

    isMobile() {
        return this.helper.isMobile();
    }
}
