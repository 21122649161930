import { Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-bottom-tab',
  templateUrl: './bottom-tab.component.html',
  styleUrls: ['./bottom-tab.component.css']
})
export class BottomTabComponent implements OnInit {

  currentRoute : string;

  constructor(private route : ActivatedRoute) { }

  ngOnInit() {

    const routes = this.route.snapshot.url;
      if (routes.length > 1) {
        this.currentRoute = routes[0] + '/' + routes[1];
      } else {
        if (routes.length === 1) {
            this.currentRoute = routes[0] + '';
          }
      }
  }

}
