export class FormValue {
  private name : string;
  private value : string;
  private regex : string;

  constructor(){
    this.name = '';
    this.value = '';
    this.regex = '';
  }

  getName(){
    return this.name;
  }

  getValue(){
    return this.value;
  }

  setName(name : string){
    this.name = name;
  }

  setValue(value : string){
    this.value = value;
  }

  setRegex(regex : string){
    this.regex  = regex;
  }

  getRegex(){
    return this.regex;
  }
}
