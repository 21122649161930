import { Component, OnInit} from '@angular/core';
import { HelperService } from '../helper.service';
import { StoreService } from '../store.service';
import { HttpService } from '../http.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { User } from '../classes';
declare var $ : any;


@Component({
  selector: 'app-home-agent',
  templateUrl: './home-agent.component.html',
  styleUrls: ['./home-agent.component.css']
})

export class HomeAgentComponent implements OnInit {

  products = {
    momo: {
      title: 'mobile_money',
      second_title: 'mobile_money',
      item_header: 'transaction',
      api: 'recharge',
      need_pin : true,
      payment_view : 'payment',
      mode : 'agent',
      zone : 'agent',
      category: null,
      recharge : false,
      withname : true
    },
    recharge: {
      title: 'topup_with',
      second_title: 'topup_account',
      item_header: 'topup_account_with',
      api: 'recharge',
      need_pin: false,
      need_code: true,
      payment_view: 'payment',
      mode: 'client',
      zone : 'agent',
      category: 'cashout',
      recharge : true,
      withname : false
    }
  };

  modalTitle : string;
  modalText : string;
  modalType : string;
  product : any;

  padVisible = false;
  numpad = [];
  mainDeposit = null;
  mainBalance = null;
  user : User;

  loading : boolean;
  request : string;
  services : any;

  constructor(private helper : HelperService, private store : StoreService,
    private http : HttpService, private translate : TranslateService, private router : Router) { }

  ngOnInit() {
    this.user = this.store.getUser();
  }

  checkClass(elt){
    if(!this.product) return false;
    return !this.helper.isMobile() && (this.product.title === elt.title);
  }

  goTo(route : string, data : JSON) : void {
    this.router.navigateByUrl(route,{state : {product :data}});
  }

  goToService(data){
    this.goTo('agent/services', data);
  }

  setModal(type : string, text : string, title : string) {

    this.modalType = type;

    switch (type) {
      case 'processing' : {
        this.modalTitle = title;
        this.translate.get('please_wait').subscribe((res: string) => {
          this.modalText = res;
        });
        break;
      }

      case 'warning' : {
        this.modalTitle = '';
        this.translate.get('failed').subscribe((res: string) => {
          this.modalText = res;
        });
        break;
      }

      case 'danger' : {
        this.modalTitle = '';
        this.modalText = text;
        break;
      }

      default : break;
    }

  }

  dismissModal(timer : number, dest : string){
    setTimeout(() => {
      $('#modal').modal('toggle');
      (dest) ? this.router.navigateByUrl(dest) : null;
    }, timer);
  }


  balance() {
		let data = {
      phone : this.user.phonecompte,
      mode : 'agent',
      agentid : this.user.agent.idagent
    };

		this.setModal('processing', 'please_wait', 'checking_balance');
    $('#modal').modal('toggle');



		this.http.getBalance(data).subscribe((response : any) => {
			if (response) {
				if (response.code === 200) {
					this.dismissModal(1,null);
          this.mainBalance = response.result.mainbalance;
          this.mainDeposit = response.result.maindeposit;
				} else {
					this.setModal('danger', response.message || '', '');
          this.dismissModal(2500, null);
				}
			} else {
				this.setModal('warning', 'failed', '');
        this.dismissModal(2500, null);
			}
		});
	}

}
