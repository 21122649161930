import { Component, OnInit } from "@angular/core";
import { DialogBoxService } from "./dialog-box.service";
import { DialogBox } from "./DialogBox";
declare var $: any;

@Component({
    selector: "app-dialog-box",
    templateUrl: "./dialog-box.component.html",
    styleUrls: ["./dialog-box.component.css"],
})
export class DialogBoxComponent implements OnInit {
    db: DialogBox;

    onCancel: Function;
    onConfirm: Function;

    constructor() {}

    ngOnInit(): void {}

    public set(db: DialogBox) {
        if (this.db == undefined) this.db = new DialogBox();
        this.db.clone(db);
    }

    toggle() {
        $("#modalDialogBox").toggle();
    }

    setOnCancel(onCancel: Function) {
        this.onCancel = onCancel;
    }

    setOnConfirm(onConfirm: Function) {
        this.onConfirm = onConfirm;
    }

    cancel() {
        this.onCancel();
    }

    confirm() {
        this.onConfirm();
    }
}
